import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ApplicantDtos } from "src/Services/Dtos/ApplicantDtos";
import { AiOutlinePrinter } from "react-icons/ai";
import { GetApplicantDataById } from "src/Services/API/ApplicantService";
import styles from "./CurriculumVitae.module.scss";
import classNames from "classnames";
import moment from "moment";
import EducationSection from "./EducationSection";
import Loading from "src/components/Loading/Loading";
import { PhotoZoom } from "./PhotoZoom/PhotoZoom";
const CLOUDINARYURL = process.env.REACT_APP_GET_CLOUDINARY_URL;
const CurriculumVitae = () => {
  const [applicantData, setApplicantData] = useState<ApplicantDtos>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState("");
  const { id } = useParams();

  const handlePrint = () => {
    window.print();
  };
  useEffect(() => {
    fetchApplicantDataById().catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function fetchApplicantDataById() {
    setIsLoading(true);
    const response = await GetApplicantDataById(id);

    setIsLoading(false);
    if (response) {
      setApplicantData(response.result.data);
    }
  }

  const attributes = {
    ...applicantData?.attributes,
  } as ApplicantDtos["attributes"];
  const { name, surname, phoneNumber, ...rest } = attributes;

  const formatPhoneNumber = (phoneNumber: any) => {
    const phoneString = phoneNumber?.toString();
    return phoneString
      ? `+90 ${phoneString.slice(0, 3)} ${phoneString.slice(
          3,
          6
        )} ${phoneString.slice(6, 8)} ${phoneString.slice(8)}`
      : "";
  };
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
  const formattedReferencePhoneNumber = formatPhoneNumber(
    rest.referencePhoneNumber
  );

  const langName = rest.language?.map((lang: any, index: number) => (
    <li key={index}>
      {lang.languageName} - {lang.languageLevel}
    </li>
  ));

  const departmentChoiceList = rest?.departmentChoices?.map((dep: any) => (
    <li key={dep}>{dep}</li>
  ));
  const positionList = rest.position?.map((pos: any) => (
    <li key={pos}>{pos}</li>
  ));
  const nation = rest.nationality?.map((nation: any) => nation).join(", ");
  const licence = rest.drivingLicence
    ?.map((licenceType: any) => licenceType)
    .join(", ");
  const isReferenceSectionIsVisible =
    rest.referenceName &&
    rest.referenceJob &&
    rest.referencePhoneNumber &&
    rest.referenceEmail;
  const isCourseSectionExist = rest.courseName && rest.courseYear;
  const image = rest.imageUrl
    ? rest.imageUrl
    : "kanerImages/cv-default_yj651r.jpg";
  return (
    <div className={styles.cvRoot}>
      {isLoading ? (
        <Loading label={"Yükleniyor..."} />
      ) : (
        <>
          <div className={styles.cvContainer}>
            <div className={styles.cvHeader}>
              <div className={styles.profileImage}>
                <img
                  alt="profile"
                  width={"100%"}
                  height={"100%"}
                  onClick={() => setSelectedImage(image)}
                  src={`${CLOUDINARYURL}${image}`}
                />
              </div>
              <p className={styles.headerText}>{`${name} ${surname}`}</p>
            </div>
            <div className={styles.border}></div>
            <div className={styles.section}>
              <h4 className={styles.sectionHeader}>İletişim Bilgileri</h4>
              <div className={styles.applicantInfoContainer}>
                <div className={styles.labelText}>
                  <h5>E-Posta :</h5> <span>{rest.email}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>Telefon Numarası:</h5> <span>{formattedPhoneNumber}</span>
                </div>
              </div>
            </div>
            <div className={styles.border}></div>
            <div className={styles.section}>
              <h4 className={styles.sectionHeader}>Kişisel Bilgiler</h4>
              <div className={styles.applicantInfoContainer}>
                <div className={styles.labelText}>
                  <h5>Doğum Tarihi:</h5>{" "}
                  <span> {moment(rest.birthDate).format("LL")}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>Doğum Yeri:</h5> <span>{rest.birthPlace}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>Cinsiyet:</h5> <span>{rest.gender}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>Medeni Durum:</h5> <span>{rest.maritalStatus}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>Uyruk:</h5> <span>{nation}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>İkamet İlçesi :</h5> <span>{rest.accomodationPlace}</span>
                </div>
                <div className={styles.labelText}>
                  <h5>Ehliyet :</h5> <span>{licence}</span>
                </div>
              </div>
            </div>
            <div className={styles.border}></div>
            <EducationSection rest={rest} />
            <div className={styles.border}></div>
            <div className={styles.section}>
              <h4 className={styles.sectionHeader}>Başvuru Bilgileri</h4>
              <div className={styles.applicantInfoContainer}>
                <div className={styles.applicantInfoTextContainer}>
                  <h5> Bölüm Tercihleri</h5>
                  <span> {departmentChoiceList}</span>
                </div>
                <div className={styles.applicantInfoTextContainer}>
                  <h5> Pozisyon</h5> <span> {positionList}</span>
                </div>
              </div>
            </div>
            <div className={styles.border}></div>

            {langName?.length !== 0 && (
              <div className={styles.section}>
                <h4 className={styles.sectionHeader}>Yabancı Dil Bilgisi</h4>
                <div className={styles.applicantInfoContainer}>
                  <div className={styles.applicantInfoTextContainer}>
                    <span> {langName}</span>
                  </div>
                </div>
              </div>
            )}
            {isCourseSectionExist && (
              <>
                <div className={styles.border}></div>{" "}
                <CourseSection rest={rest} />
              </>
            )}
            {isReferenceSectionIsVisible && (
              <>
                <div className={styles.border}></div>
                <ReferenceSection
                  rest={rest}
                  formattedReferencePhoneNumber={formattedReferencePhoneNumber}
                />
              </>
            )}
            {rest.techKnowledge && (
              <>
                <div className={styles.border}></div>
                <div className={styles.section}>
                  <h4 className={styles.sectionHeader}>Teknoloji Bilgileri</h4>
                  <div className={styles.applicantInfoContainer}>
                    <div className={styles.labelText}>
                      <span> {rest.techKnowledge}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {rest.coverLetter && (
              <>
                <div className={styles.border}></div>
                <div className={styles.section}>
                  <h4 className={styles.sectionHeader}>Ön Yazı</h4>
                  <div>
                    <div className={styles.coverLetterText}>
                      <span> {rest.coverLetter}</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {rest.careerInfo === "Deneyimli" ? (
              <>
                <div className={styles.border}></div>
                <div className={styles.section}>
                  <h4 className={styles.sectionHeader}>İş Deneyimleri </h4>
                  <div className={styles.experienceContainer}>
                    {rest.experience?.map((item: any, index: number) => (
                      <div className={styles.applicantExperienceContainer}>
                        <div
                          className={classNames(
                            styles.applicantInfoTextContainer,
                            styles.experienceInfoText
                          )}
                        >
                          <p className={styles.experienceHeader}> Şirket Adı</p>
                          <span>{item.workName}</span>
                        </div>
                        <div
                          className={classNames(
                            styles.applicantInfoTextContainer,
                            styles.experienceInfoText
                          )}
                        >
                          <p className={styles.experienceHeader}> Ünvan</p>
                          <span>{item.jobTitle}</span>
                        </div>
                        <div
                          className={classNames(
                            styles.applicantInfoTextContainer,
                            styles.experienceInfoText
                          )}
                        >
                          <p className={styles.experienceHeader}>
                            {" "}
                            Başlama Tarihi
                          </p>
                          <span>{moment(item.startDate).format("LL")}</span>
                        </div>
                        <div
                          className={classNames(
                            styles.applicantInfoTextContainer,
                            styles.experienceInfoText
                          )}
                        >
                          <p className={styles.experienceHeader}>
                            {" "}
                            Hala çalışıyor mu?
                          </p>
                          <span>{item.isCurrentlyWorking}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.border}></div>
                <div className={styles.section}>
                  <h4 className={styles.sectionHeader}>İş Deneyimleri </h4>
                  <div className={styles.experienceContainer}>
                    <div className={styles.applicantInfoTextContainer}>
                      <span>Deneyimsiz</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={classNames(styles.footerCv, styles.noPrint)}>
            <Link to={"/başvuran-listesi"} className={styles.link}>
              İK Sayfasına Dön
            </Link>
            <button className={styles.print} onClick={handlePrint}>
              Print <AiOutlinePrinter size={20} />
            </button>
          </div>
          {selectedImage && (
            <PhotoZoom
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CurriculumVitae;

export const ReferenceSection = ({
  rest,
  formattedReferencePhoneNumber,
}: any) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.sectionHeader}>Referans Bilgileri</h4>
      <div className={styles.applicantInfoContainer}>
        <div className={styles.labelText}>
          <h5>İsim-Soyisim:</h5> <span> {rest.referenceName}</span>
        </div>
        <div className={styles.labelText}>
          <h5>E-Posta:</h5> <span>{rest.referenceEmail}</span>
        </div>
        <div className={styles.labelText}>
          <h5>İletişim Numarası:</h5>{" "}
          <span>{formattedReferencePhoneNumber}</span>
        </div>

        <div className={styles.labelText}>
          <h5> Görevi:</h5> <span>{rest.referenceJob}</span>
        </div>
      </div>
    </div>
  );
};
export const CourseSection = ({ rest }: any) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.sectionHeader}>Kurs & Sertifika Bilgileri</h4>
      <div className={styles.applicantInfoContainer}>
        <div className={styles.labelText}>
          <h5>Kurs Dalı:</h5> <span> {rest.courseName}</span>
        </div>
        <div className={styles.labelText}>
          <h5>Yıl:</h5> <span>{moment(rest.courseYear).format("YYYY")}</span>
        </div>
      </div>
    </div>
  );
};
