/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Modal } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import styles from "./ApplicantCard.module.scss";
import CustomisedButton from "src/components/Button/Button";
import { useState } from "react";

interface Applicant {
  key: string;
  imageUrl: string;
  name: string;
  surname: string;
  location: string;
  email: string;
  date: string;
  drivingLicence?: string[];
  departmentChoices?: string[];
  position?: string[];
}

interface Review {
  id: number;
  attributes: {
    applicantId: string;
  };
}

interface ApplicantCardProps {
  applicant: Applicant;
  reviews: Review[];
  handleApplicantId: (key: string) => void;
  handleReviewModal: (key: string) => void;
  status: any;
}
const CLOUDINARY_GET_URL = process.env.REACT_APP_GET_CLOUDINARY_URL;

export const ApplicantCard: React.FC<ApplicantCardProps> = ({
  applicant,
  reviews,
  handleApplicantId,
  handleReviewModal,
  status,
}) => {
  const applicantStatus = status?.find(
    (item: any) => item.attributes.applicantId === applicant.key
  );
  const [deleteModal, setDeleteModal] = useState(false);
  function truncateText(text: string | undefined, maxLength: number) {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "..."; // Truncate and add ellipsis
    }
    return text;
  }
  const statusLabel = applicantStatus
    ? applicantStatus.attributes.status
    : "Yeni Basvuru";
  const image = applicant.imageUrl
    ? applicant.imageUrl
    : "kanerImages/cv-default_yj651r.jpg";
  return (
    <div key={applicant.key} className={styles.cardContainer}>
      <div className={styles.profileContainer}>
        <img
          alt="profile"
          width={"100px"}
          height={"100px"}
          src={`${CLOUDINARY_GET_URL}${image}`}
        />
        <div className={styles.statusContainer}>
          <h5 className={styles.nameOfApplicant}>
            {applicant.name} {applicant.surname}
          </h5>
          <CustomisedButton
            style={styles.button}
            labelColor="#F47C01"
            borderColor="#F47C01"
            label={statusLabel}
          />
        </div>
      </div>
      <div className={styles.applicantInfo}>
        <div className={styles.deepInfo}>
          <div className={styles.location}>
            <GoLocation />
            <h6 className={styles.smallHeader}>{applicant.location}</h6>
          </div>
          <h6 className={styles.smallHeader}>Email: {applicant.email}</h6>
          <h6 className={styles.smallHeader}>
            Başvuru Tarihi: {applicant.date}
          </h6>
          <h6 className={styles.smallHeader}>
            Sürüş Ehliyeti: {applicant?.drivingLicence?.join(", ")}
          </h6>
          <h6 className={styles.smallHeader}>
            Bölüm Tercihi:{" "}
            {truncateText(applicant?.departmentChoices?.join(", "), 150)}
          </h6>
          <h6 className={styles.smallHeader}>
            Pozisyon: {truncateText(applicant?.position?.join(", "), 150)}
          </h6>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={() => handleApplicantId(applicant.key)}>
            CV Gör
          </Button>
          {reviews.some(
            (review: any) => review.attributes.applicantId === applicant.key
          ) ? (
            <Button onClick={() => handleReviewModal(applicant.key)}>
              <AiOutlineCheckCircle size={20} className={styles.reviewIcon} />
            </Button>
          ) : (
            <Button onClick={() => handleReviewModal(applicant.key)}>
              Değerlendir
            </Button>
          )}
        </div>
      </div>
      {deleteModal && (
        <Modal
          open={deleteModal}
          width={"50%"}
          onCancel={() => setDeleteModal(false)}
        >
          Başvuranı silmek istediğinizden emin misiniz?
        </Modal>
      )}
    </div>
  );
};
